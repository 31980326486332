import { useEffect } from "react";

interface Props {
  path: string;
  size: [number, number];
  id: string;
}

// This hook must only be called once per page

export default (props: Props[]) => {
  useEffect(() => {
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(() => {
      window.googletag.destroySlots();
    });

    props.map((item) => {
      googletag.cmd.push(function () {
        googletag.defineSlot(item.path, item.size, item.id).addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });
      googletag.cmd.push(function () {
        googletag.display(item.id);
      });
    });
  }, [props]);
};
