import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingComp from "../components/re-usable/loading/loading";
import EuropeRouter from "../routes/europe-2024-routes";
import EuropeHeader from "../components/header/europe-2024-header";
import EuropeLayoutStyleComponent from "./components/europe-2024-layout-style.component";
import Footer from "../components/footer/footer";

const Layout = () => {

  return (
    <>
      <EuropeLayoutStyleComponent />
      <div>
        <EuropeHeader />
        <Suspense fallback={<LoadingComp />}>
          <Routes>
            {EuropeRouter.map((route, index) => {
              return route.component ? <Route key={index} path={route.path} element={route.component} /> : null;
            })}
          </Routes>
        </Suspense>
        <Footer />
      </div >
    </>
  );
};

export default Layout;