import React, { Suspense, useEffect, useState } from "react";
import { Route, Navigate, Routes } from "react-router-dom";

import Cookies from "../components/cookies/cookies";
import Footer from "../components/footer/footer";
import HeaderBar from "../components/header/header-bar";
import AppRouter from "../routes";
import SiteAlerts from "../components/re-usable/alerts/site-alerts";
import { RootState, useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import { retrieveNotification } from "../redux/slice/notification-slice";
import LoadingComp from "../components/re-usable/loading/loading";
import useRawRedirectUrls from "../hooks/useRawRedirectUrls";

const RedirectUrlAllPage = React.lazy(() => import("../pages/redirects/redirect-all"));

type RedirectUrlType = {
  oldUrl: string;
  newUrl: string;
};

const DefaultLayout = () => {
  const { redirectUrls } = useRawRedirectUrls();

  const dispatch = useAppDispatch();

  const { notificationData } = useSelector((state: RootState) => state.notification);
  const allRedirectUrls = useSelector((state: RootState) => state.allRedirectUrls);

  const [redirectUrlList, setRedirectUrlList] = useState<RedirectUrlType[] | null>(redirectUrls?.redirectList ?? null);

  useEffect(() => {
    dispatch(retrieveNotification());
  }, []);

  useEffect(() => {
    if (redirectUrls === null && allRedirectUrls.data.urlRedirectLists !== undefined) {
      setRedirectUrlList(allRedirectUrls.data.urlRedirectLists);
    }
  }, [allRedirectUrls.data]);

  if (redirectUrlList === null) return <LoadingComp />;

  return (
    <>
      <Cookies />
      <HeaderBar />
      <div className="sitealert container text-center">
        <b>
          <SiteAlerts alertData={notificationData[0]?.notificationMenuData[0]?.rapsUpdates}></SiteAlerts>
        </b>
      </div>
      <Suspense fallback={<LoadingComp />}>
        <Routes>
          {redirectUrlList !== null &&
            redirectUrlList.map((route: RedirectUrlType, index: number) => {
              return (
                <Route
                  key={index}
                  path={route.oldUrl}
                  element={
                    route.newUrl.match(/^(https?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/) ? (
                      <RedirectUrlAllPage redirectUrl={route.newUrl} />
                    ) : (
                      <Navigate to={route.newUrl} replace />
                    )
                  }
                />
              );
            })}
          {AppRouter.map((route, index) => {
            return route.component ? <Route key={index} path={route.path} element={route.component} /> : null;
          })}
        </Routes>
      </Suspense>
      <Footer />
      {/*<SvgIcons />*/}
    </>
  );
};

export default DefaultLayout;
