
import http from "../../http-common";

class BooksTabsService {
    getBooksTabs(path: string, pageNumber: number) {
        return http.get(`/education/books/tabs/${path}?pageNumber=${pageNumber}`);
    }
}

export default new BooksTabsService();
