import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { retrieveMasterPageConfiguration } from "../../redux/slice/master-page-configuration-slice";
import { retrieveSocialMediaContent } from "../../redux/slice/social-media-content-slice";
import { retrieveFooterMenu } from "../../redux/slice/footer-menu.slice";
import { RootState, useAppDispatch } from "../../redux/store";
import { Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Footer = (props: { footerClass?: string }) => {
  const urlLocation = useLocation()

  const { masterPageConfigurationData } = useSelector((state: RootState) => state.masterPageConfiguration)
  const { socialMediaContentData } = useSelector((state: RootState) => state.socialMediaContent)
  const { FooterMenuData } = useSelector((state: RootState) => state.footerMenu)
  const dispatch = useAppDispatch()
  const getYear = new Date().getFullYear();
  useEffect(() => {
    dispatch(retrieveMasterPageConfiguration())
    dispatch(retrieveSocialMediaContent())
    dispatch(retrieveFooterMenu())
    //setFormValidation();
  }, [dispatch])


  const [localAddress, setLocalAddress] = useState("");
  const [localPhone, setLocalPhone] = useState("");
  const [localFax, setLocalFax] = useState("");
  const [localEmail, setEmail] = useState("");

  useEffect(() => {

    var masterPageContent = masterPageConfigurationData[0]?.masterPageData[0];
    setLocalAddress(masterPageContent?.rapsAddress)
    setLocalPhone(masterPageContent?.rapsPhoneNumber)
    setLocalFax(masterPageContent?.rapsFaxNumber)
    setEmail(masterPageContent?.rapsEmail)

    if (urlLocation.pathname.startsWith("/europe-")) {
      if (masterPageContent?.europeAddress !== '') setLocalAddress(masterPageContent?.europeAddress)
      if (masterPageContent?.europePhoneNumber !== '') setLocalPhone(masterPageContent?.europePhoneNumber)
      if (masterPageContent?.europeFaxNumber !== '') setLocalFax(masterPageContent?.europeFaxNumber)
    }

  }, [masterPageConfigurationData, urlLocation])

  return (
    <>
      <footer className={`site-footer ${(props.footerClass) ? props.footerClass : ''}`} id="site-footer" role="contentinfo">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact-info contact-left">
                <address><p><div dangerouslySetInnerHTML={{ __html: `${localAddress}` }}></div></p></address>
                <div className="contact-phone">
                  <span>P <a href={`tel:${localPhone}`}>{localPhone}</a>&nbsp;</span>
                  <span>F {localFax}</span>
                </div>
                <div className="contact-email">Email: <a href={`mailto:${localEmail}`} >{localEmail}</a></div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-6 col-xs-6">
                  <div className="footer-nav">
                    <ul className="list-unstyled">
                      {
                        FooterMenuData[0]?.menuData.map((item, index) => {
                          return <React.Fragment key={index}>
                            {(item.menuItemGroups === "FooterLeft") ? <li><Nav.Link href={item.navigationURL}>         {item.menuTitle}</Nav.Link></li> : ''}
                          </React.Fragment>
                        })
                      }
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-xs-6">
                  <div className="footer-nav">
                    <ul className="list-unstyled">
                      {
                        FooterMenuData[0]?.menuData.map((item, index) => {
                          return (item.menuItemGroups === "FooterRight") ?
                            (item.navigationURL.startsWith("#")) ?
                              <li key={index}><a data-target={item.navigationURL} data-toggle="modal">{item.menuTitle}</a></li>
                              : <li key={index}><Nav.Link href={item.navigationURL}>{item.menuTitle}</Nav.Link></li>
                            : ''
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <ul className="social-icomoons social-media-icons list-inline">
                {
                  socialMediaContentData[0]?.socialMediaData?.map((item, index) => {
                    return <li key={index}>
                      {
                        (item.socialMediaSVGClass) ? (
                          <a href={item.redirectionUrl} target="_blank" title={item.socialMediaTitle} rel="noreferrer">
                            <i className={`fa-brands fa-xl ${item.socialMediaSVGClass}`} title={item.socialMediaTitle}></i>
                            <span className="sr-only">{item.socialMediaTitle}</span>
                          </a>
                        ) : (
                          <a className="img-icon" href={item.redirectionUrl} target="_blank" title={item.socialMediaTitle} rel="noreferrer">
                            <img src={item.socialMediaImage} alt={item.socialMediaTitle} />
                            <span className="sr-only">{item.socialMediaTitle}</span>
                          </a>
                        )
                      }
                    </li>
                  })
                }
              </ul>
              {
                masterPageConfigurationData[0]?.masterPageData?.map((item, index) => {
                  return <p className="copyright" key={index}>&copy; {getYear}<br /> {item.rapsCopyRightText} </p>

                })
              }
              <p className="ae">&nbsp;</p>
            </div>
          </div>
        </div>
        <FooterStyles />
      </footer>
      {/*<EventsSponsorship />*/}
    </>
  );
}

const setFormValidation = () => {
  let submitButton = document.querySelector("#exhibitorSubmit");
  let checkboxInput = $("#exhibitorConsent");
  checkboxInput?.on("click", function () {
    if (checkboxInput?.prop("checked") === true) {
      console.log(checkboxInput?.prop("checked") === true);
      submitButton?.removeAttribute("disabled");
    } else {
      console.log(checkboxInput?.prop("checked") === true);
      submitButton?.setAttribute("disabled", "disabled");
    }
  });
}

const FooterStyles = () => {
  return (
    <>
      <style>
        {`
        .site-footer .container {
          max-width: 1440px;
        }
        .site-footer {
            left: 0;
            position: relative;
            transition: left .3s;
            background-color: #fff
        }
        .site-footer {
            padding: 60px 0;
            background: #19204A;
            color: #fff;
            text-align: center
        }

            .site-footer a {
                color: inherit;
                font-weight: 700
            }
            .site-footer .nav-link {
                color: white;
            }

            .site-footer .social-icomoons {
                margin-top: 20px;
                margin-bottom: 30px
            }

                .site-footer .social-icomoons li {
                    padding-left: 3px;
                    padding-right: 3px;
                    margin-bottom: 10px;
                }

                .site-footer .social-icomoons .icomoon {
                    border: 2px solid #fff;
                    padding: 4px;
                    height: 36px;
                    width: 36px;
                    fill: #fff;
                    overflow: visible;
                    border-radius: 17px;
                }

        .site-footer .contact-phone span {
            display: block
        }

        .site-footer .nav-link {
          padding: unset;
        }

        @media (min-width:60em) {
            .site-footer {
                text-align: left
            }

                .site-footer .contact-info {
                    line-height: 1.8
                }

                    .site-footer .contact-info p-off {
                        margin: 0
                    }

                    .site-footer .contact-info address {
                        margin-bottom: 0;
                        line-height: inherit
                    }

                    .site-footer .contact-info .contact-phone span {
                        display: inline-block
                    }

                        .site-footer .contact-info .contact-phone span + span:before {
                            content: " | "
                        }

                .site-footer .ae, .site-footer .copyright, .site-footer .social-icomoons {
                    text-align: right;
                    margin-top: 0
                }
        }

        .footer-nav a {
            text-transform: uppercase;
            cursor: pointer;
        }

        .footer-nav ul li {
            line-height: 2
        }

        @media (min-width:48.063em) {
            .footer-nav .menu {
                display: table;
                list-style: none;
                width: 100%
            }

                .footer-nav .menu > li {
                    display: table-cell
                }

                    .footer-nav .menu > li ul {
                        list-style: none;
                        margin: 0;
                        padding: 0
                    }
        }

        .social-media-icons a {
          padding: 7px 3px;
          background-color: white;
          border-radius: 7px;
          margin-right: 4px;
        }
        .social-media-icons i {
          color: #18204A;
          padding: 4px;
        }
        .social-media-icons i.fa-facebook-f {
          padding: 8px;
        }
      `}
      </style>
    </>
  );
}
export default Footer;