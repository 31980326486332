import { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAppDispatch } from "../redux/store";
import { retrieveNotification } from "../redux/slice/notification-slice";
import LoadingComp from "../components/re-usable/loading/loading";
import Router from "../routes/convergence-2024-routes";
import Header from "../components/header/convergence-2024-header";
import LayoutStyleComponent from "./components/convergence-2024-layout-style.component";
import Footer from "../components/footer/footer";

const Layout = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(retrieveNotification())
  })
  return (
    <>
      <LayoutStyleComponent />
      <div>
        <Header />
        <Suspense fallback={<LoadingComp />}>
          <Routes>
            {Router.map((route, index) => {
              return route.component ? <Route key={index} path={route.path} element={route.component} /> : null;
            })}
          </Routes>
        </Suspense>
        <Footer />
      </div >
    </>
  );
};

export default Layout;