import { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Navigate, Routes } from "react-router-dom";

import Convergence2023 from "../routes/convergence-2023-routes";
import Convergence2023Footer from "../components/footer/convergence-2023-footer";
import SvgIcons from "../components/re-usable/icons/svg-icons";
import { RootState, useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import { retrieveNotification } from "../redux/slice/notification-slice";
import LoadingComp from "../components/re-usable/loading/loading";
import Convergence2023Header from "../components/header/convergence-2023-header";
import Convergence2023LayoutStyleComponent from "./components/convergence-2023-layout-style.component";
import Footer from "../components/footer/footer";
// import  "../assets/styles/convergence-2023-app.min.css";
// import  "../assets/styles/convergence-2023.css"

const Convergence2023Layout = () => {
    const dispatch = useAppDispatch()

    const { notificationData, notificationLoading, notificationError } = useSelector((state: RootState) => state.notification)

    useEffect(() => {
        dispatch(retrieveNotification())
    }, [dispatch])

    const notification = notificationData.map((notification) => {
        return notification.notificationMenuData.map((item) => {
            return item.rapsUpdates;
        })
    })

    return (
        <>
            <Convergence2023LayoutStyleComponent />
            <div>
                <Convergence2023Header />
                <Suspense fallback={<LoadingComp />}>
                    <Routes>
                        {Convergence2023.map((route, index) => {
                            return route.component ? <Route key={index} path={route.path} element={route.component} /> : null;
                        })}
                    </Routes>
                </Suspense>
                <Footer />
                {/*<Convergence2023Footer footerClass="bg-primary text-light mt-4" />
                <SvgIcons />*/}
            </div >
        </>
    );

};

export default Convergence2023Layout;
