import { useEffect } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { retrieveConvergencePageMenu } from "../../redux/slice/convergence/convergence-pages-menu-slice";
import { RootState, useAppDispatch } from "../../redux/store";

const RIConferenceHeader = () => {

  const dispatch = useAppDispatch()
  const path = "%2FRIConf%2FSite-Nav";
  const { ConvergencePagesMenuData } = useSelector((state: RootState) => state.ConvergencePageMenu)
  useEffect(() => {
    dispatch(retrieveConvergencePageMenu(path))
  }, [dispatch])

  return (
    <header className="convergence-header">
      <Navbar bg="dark" className="bg-primary fixed-top navbar-dark" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <ul className="navbar-nav mr-auto">
              {ConvergencePagesMenuData[0]?.navBarItemsList?.map((item, index) => {
                return item.childNavigation?.length === 0 ? (
                  <li className="nav-item  " key={index}>
                    <Nav.Link href={item.url} target={item.isTargetBlank ? "_blank" : ""} dangerouslySetInnerHTML={{ __html: item.text }}></Nav.Link>
                  </li>
                ) : (
                  <li className="nav-item dropdown " key={index}>
                    <NavDropdown id={"nav-dropdown-dark-example" + index} title={item.text} menuVariant="dark" renderMenuOnMount={true}>
                      {item.childNavigation?.map((itm, index) => {
                        return <NavDropdown.Item href={itm.url} key={index} target={itm.isTargetBlank ? "_blank" : ""} dangerouslySetInnerHTML={{ __html: itm.text }}></NavDropdown.Item>;
                      })}
                    </NavDropdown>
                  </li>
                );
              })}
            </ul>
          </Nav>
        </Navbar.Collapse>
        <div className="form-inline">
          <Link to="/riconf/register" className="btn btn-success text-light mr-4" role="button">Register</Link>
          <Link className="btn btn-primary" style={{ marginRight: 0 }} to="/">RAPS.ORG</Link>
        </div>
      </Navbar>
    </header>
  );
};

export default RIConferenceHeader;