import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ConferenceRoutes from "../routes/eucrm-routes";
import LoadingComp from "../components/re-usable/loading/loading";
import ConferenceHeader from "../components/header/eucrm-header";
import ConferenceLayoutStyleComponent from "./components/eucrm-layout-style.component";
import Footer from "../components/footer/footer";

const EUCRMConferenceLayout = () => {

  return (
    <>
      <ConferenceLayoutStyleComponent />
      <div>
        <ConferenceHeader />
        <Suspense fallback={<LoadingComp />}>
          <Routes>
            {ConferenceRoutes.map((route, index) => {
              return route.component ? <Route key={index} path={route.path} element={route.component} /> : null;
            })}
          </Routes>
        </Suspense>
        <Footer />
      </div >
    </>
  );

};

export default EUCRMConferenceLayout;
