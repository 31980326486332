import useDfpSlot from "../../hooks/useDfpSlot";
import { useEffect } from "react";

const LoadConvergenceAds = () => {
  useDfpSlot([
    {
      path: "/22240682100/Convergence_970x90_TopBanner",
      size: [970, 90],
      id: "div-gpt-ad-1692883213405-0",
    },
    {
      path: "/22240682100/Convergence_300x50_TopBanner",
      size: [300, 50],
      id: "div-gpt-ad-1692883068939-0",
    },
  ]);

  return <RenderAds/>;
};

export default LoadConvergenceAds;
const RenderAds = () => {
  useEffect(() => {
    setAdScroll()
  }, []);

  return (
    <>
      <div className="adsdiv" style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "5px auto" }}>
        {/* /22240682100/Convergence_970x90 */}
        <div className="CMSBanner Banner topbanner gadsbanner" id="div-gpt-ad-1692883213405-0" style={{ width: "970px", height: "90px" }}></div>
        {/* /22240682100/Convergence_300x50_TopBanner */}
        <div className="CMSBanner Banner mobilebanner gadsbanner" id="div-gpt-ad-1692883068939-0" style={{ width: "300px", height: "50px" }}></div>
      </div>
      <style>{`
        @media only screen and (min-width: 1025px) {
          .topbanner { display: block; }
          .mobilebanner { display: none; }
        }
        @media only screen and (max-width: 1025px) {
         .topbanner { display: none; }
         .mobilebanner { display: block; }
        }
         /* Comment during off-season 
        .fixed-top { top: 5px; position: relative; }
        #header-image { margin-top: 5px }
        body { padding-top: 5px !important; }*/
      `}</style>
    </>
  );
};

function setAdScroll() {
  $(function () {
    window.onscroll = function () { navbarPosition() };
    var sticky = $('.navbar').offset()?.top;

    function navbarPosition() {
      var adsdiv = 0;// ($('.topbanner').css('display') == 'none') ? 0 : 90;
      if (window.pageYOffset >= (sticky ?? 0)) {
        $('.navbar').css('position', 'fixed');
        $('.navbar').css('top', 0 + adsdiv);
      } else {
        $('.navbar').css('position', 'relative');
        $('.navbar').css('top', 5 + adsdiv);
      }
    }
    navbarPosition();
  });
}