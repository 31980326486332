import { useEffect } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { retrieveConvergencePageMenu } from "../../redux/slice/convergence/convergence-pages-menu-slice";
import { RootState, useAppDispatch } from "../../redux/store";

const GRSConferenceHeader = () => {

  const dispatch = useAppDispatch()
  const path = "%2FGRS%2FSite-Nav";
  const { ConvergencePagesMenuData } = useSelector((state: RootState) => state.ConvergencePageMenu)
  useEffect(() => {
    dispatch(retrieveConvergencePageMenu(path))
  }, [dispatch])

  return (
    <header className="convergence-header">
      <Navbar bg="dark" className="bg-primary fixed-top navbar-dark" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {ConvergencePagesMenuData[0]?.navBarItemsList?.map((item, index) => {
              return (item.childNavigation?.length === 0) ? (
                <Nav.Link href={item.url} key={index} target={item.isTargetBlank ? "_blank" : ""} dangerouslySetInnerHTML={{ __html: item.text }}></Nav.Link>
              ) : (
                <NavDropdown id="nav-dropdown-dark-example" title={item.text} menuVariant="dark" key={index}>
                  {item.childNavigation?.map((itm, indx) => {
                    return <NavDropdown.Item href={itm.url} key={indx} target={itm.isTargetBlank ? "_blank" : ""} dangerouslySetInnerHTML={{ __html: itm.text }}></NavDropdown.Item>
                  })}
                </NavDropdown>
              )
            })}
          </Nav>
        </Navbar.Collapse>
        <div className="form-inline">
          <a href="/grs/register" className="btn btn-success text-light mr-4" id="topRegisterBtn" role="button">Register</a>
          <Link className="btn btn-primary" style={{ marginRight: 0 }} to="/">RAPS.ORG</Link>
        </div>
      </Navbar>
    </header>
  );
};

export default GRSConferenceHeader;