import { useEffect, useMemo } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { retrieveConvergencePageMenu } from "../../redux/slice/convergence/convergence-pages-menu-slice";
import { RootState, useAppDispatch } from "../../redux/store";
import LoadAds from "../../components/advertisement/load-diaeucp-ads";

const ConferenceHeader = () => {

  const dispatch = useAppDispatch();
  const path = "%2Feucomboprods%2FSite-Nav";
  const { ConvergencePagesMenuData } = useSelector((state: RootState) => state.ConvergencePageMenu);
  const cachedGoogleAdsLoader = useMemo(() => <LoadAds />, []);

  useEffect(() => {
    dispatch(retrieveConvergencePageMenu(path));
  }, [dispatch]);
  return (
    <>
      {/*cachedGoogleAdsLoader*/}
      <header className="convergence-header">
        <Navbar bg="dark" className="bg-primary fixed-top navbar-dark" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {ConvergencePagesMenuData[0]?.navBarItemsList?.map((item, index) => {
                return item.childNavigation?.length === 0 ? (
                  <Nav.Link href={item.url} key={index} target={item.isTargetBlank ? "_blank" : ""} dangerouslySetInnerHTML={{ __html: item.text }}></Nav.Link>
                ) : (
                    <NavDropdown id={"nav-dropdown-dark-example-" + index} title={item.text} menuVariant="dark" key={index} renderMenuOnMount={true}>
                    {item.childNavigation?.map((itm, indx) => {
                      return <NavDropdown.Item href={itm.url} key={indx} target={itm.isTargetBlank ? "_blank" : ""} dangerouslySetInnerHTML={{ __html: itm.text }}></NavDropdown.Item>;
                    })}
                  </NavDropdown>
                );
              })}
            </Nav>
          </Navbar.Collapse>
          <div className="form-inline">
            {<Link to="https://cvent.me/rnmA88" className="btn btn-success text-light mr-3" id="btnRegister" role="button" target="_blank">Register</Link>}
            <Link className="btn btn-primary mr-2" style={{ marginRight: 0 }} to="/">RAPS</Link>
            <Link className="btn btn-primary" style={{ marginRight: 0 }} to="https://www.diaglobal.org/" target="_blank">DIA</Link>
          </div>
        </Navbar>
      </header>
    </>
  );
};

export default ConferenceHeader;