import { useEffect } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { retrieveConvergencePageMenu } from "../../redux/slice/convergence/convergence-pages-menu-slice";
import { RootState, useAppDispatch } from "../../redux/store";
import logo from "../../assets/images/RAPS-Logo.png";
import { retrieveUserInfo } from "../../redux/slice/sso/userinfo.slice";
import useAuth from "../../hooks/useAuth";

const Europe2023Header = () => {
  const auth = useAuth();

  const dispatch = useAppDispatch();
  const path = "%2Feurope-2023%2FSite-Nav";
  const { ConvergencePagesMenuData } = useSelector((state: RootState) => state.ConvergencePageMenu);
  const { checkLogin } = useSelector((state: RootState) => state.loggedIn);

  const userInfo = useSelector((state: RootState) => state.userInfo);
  const userInfoData = userInfo.data;

  useEffect(() => {
    dispatch(retrieveConvergencePageMenu(path));
  }, [dispatch]);

  useEffect(() => {
    if (!userInfo.loading && auth.getUser()?.accessToken && userInfo.data?.result === undefined) {
      dispatch(retrieveUserInfo());
    }
  }, [dispatch]);

  return (
    <>
      {/*<RenderAds />*/}
      <Navbar bg="dark" className="bg-primary sticky-top navbar-dark" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {ConvergencePagesMenuData[0]?.navBarItemsList?.map((item, index) => {
              return item.childNavigation?.length === 0 ? (
                <Nav.Link href={item.url} key={index} target={item.isTargetBlank ? "_blank" : ""} dangerouslySetInnerHTML={{ __html: item.text }}></Nav.Link>
              ) : (
                <NavDropdown id={"nav-dropdown-dark-example-" + index} title={item.text} menuVariant="dark" key={index} renderMenuOnMount={true}>
                  {item.childNavigation?.map((itm, indx) => {
                    return <NavDropdown.Item href={itm.url} key={indx} target={itm.isTargetBlank ? "_blank" : ""} dangerouslySetInnerHTML={{ __html: itm.text }}></NavDropdown.Item>;
                  })}
                </NavDropdown>
              );
            })}
          </Nav>
        </Navbar.Collapse>
        <div className="form-inline">
          {checkLogin.isLoggedIn && userInfoData.result && <span className="navbar-brand greeting">Hi, {userInfoData.result?.firstName}</span>}
          <Link className="navbar-brand" style={{ marginRight: 0 }} to="/">
            <img alt="RAPS.org" src={logo} />
          </Link>
        </div>
      </Navbar>
    </>
  );
};

export default Europe2023Header;

const RenderAds = () => {
  return (
    <>
      <div className="adsdiv" style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "5px auto" }}>
        {/* /22240682100/Euro_Convergence_970x90 */}
        <div className="CMSBanner Banner topbanner gadsbanner" id="div-gpt-ad-1681241098385-0" style={{ width: "970px", height: "90px", display: "none" }}></div>
        {/* /22240682100/Euro_Convergence_300x50_TopBanner */}
        <div className="CMSBanner Banner mobilebanner gadsbanner" id="div-gpt-ad-1681912081422-0" style={{ width: "300px", height: "50px", display: "none" }}></div>
      </div>
      <style>{`
        @media only screen and (min-width: 1025px) {
          .topbanner { display: block; }
          .mobilebanner { display: none; }
        }
        @media only screen and (max-width: 1025px) {
         .topbanner { display: none; }
         .mobilebanner { display: block; }
          /*.fixed-top { top: 0px; }
          #header-image { margin-top: 0px }*/
        }
      `}</style>
    </>
  );
};
