import { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ConferenceRoutes from "../routes/riconf-routes";
import Footer from "../components/footer/footer";
import { useAppDispatch } from "../redux/store";
import { retrieveNotification } from "../redux/slice/notification-slice";
import LoadingComp from "../components/re-usable/loading/loading";
import ConferenceHeader from "../components/header/riconf-header";
import ConferenceLayoutStyleComponent from "./components/eucrm-layout-style.component";

const RIConferenceLayout = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(retrieveNotification())
  })
  return (
    <>
      <ConferenceLayoutStyleComponent />
      <div>
        <ConferenceHeader />
        <Suspense fallback={<LoadingComp />}>
          <Routes>
            {ConferenceRoutes.map((route, index) => {
              return route.component ? <Route key={index} path={route.path} element={route.component} /> : null;
            })}
          </Routes>
        </Suspense>
        <Footer />
      </div >
    </>
  );
};

export default RIConferenceLayout;