import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import trendingNewsReducer from "./slice/trending-news-slice";
import primaryMenuReducer from "./slice/primary-menu-slice";
import authenticationReducer from "./slice/sso/authentication-slice";
import convergenceCarouselSlidesSliceReducer from './slice/convergence-carousel-slides-slice';
import rapsHighlightsReducer from "./slice/raps-highlights-slice";
import taxonomyReducer from "./slice/taxonomy-slice";
import latestArticlesReducer from "./slice/latest-articles-slice";
import latestBooksReducer from "./slice/latest-books-slice";
import latestCoursesReducer from "./slice/latest-courses-slice";
import latestEventsReducer from "./slice/latest-events-slice";
import eventCalendarReducer from "./slice/event-calendar-slice";
import convergenceCarouselReducer from './slice/convergence-carousel-slice';
import notificationReducer from './slice/notification-slice';
import masterPageConfigurationReducer from './slice/master-page-configuration-slice';
import topArticlesForHomePageReducer from './slice/top-articles-for-home-page-slice';
import homeRegulatoryExchangeReducer from "./slice/home-regulatory-exchange-slice";
import latestDiscussionReducer from './slice/external/latest-discussion-slice';
import socialMediaContentReducer from './slice/social-media-content-slice';
import footerMenuReducer from './slice/footer-menu.slice';
import regulatoryExchangeReducer from './slice/regulatory-exchange';
import cookieLawReducer from './slice/cookie-law-slice';
import pageMenuReducer from './slice/page-menu.slice';
import searchRacExamReducer from './slice/search-page/search-rac-exam-slice';
import RegulatoryFocusReducer from './slice/regulatory-focus-slice';
import boardOfDirectorsReducer from './slice/board-of-editors-slice';
import BreadcrumbReducer from './slice/breadcrumb-slice';
import editorialAdvisoryCommitteeReducer from './slice/editorial-advisory-committee-slice';
import thunkMiddleware from 'redux-thunk';
import searcFiltersReducer from "./slice/search-page/search-filter-options-slice";
import searchSortByReducer from "./slice/search-page/search-order-by-options-slice";
import searchPageTabsReducer from "./slice/search-page/search-page-tab-slice";
import searchReducer from "./slice/search-page/search-slice";
import preferencesReducer from "./slice/preferences.slice";
import racMenuReducer from "./slice/rac-menu/rac-menu.slice";
import newsArticlesSearcReducer from "./slice/news-articles/news-articles-search-slice";
import newsArticlesSearchCategoryReducer from "./slice/news-articles/news-articles-search-category-slice";
import newsArticlesSearchAuthorReducer from "./slice/news-articles/news-articles-search-author-slice";

import eventDetailsReducer from './slice/events/event-details-slice';
import localNetworkReducer from "./slice/local-network.slice";
import ProductDetailsReducer from "./slice/products/product-details-slice";
import BookSaleReducer from "./slice/book-sale-slice";
import EducationPagesReducer from "./slice/education/education-pages-slice";
import WhoToArePressReleasesReducer from "./slice/who-we-are/press-releases-slice";
import WhoToAreNewsReducer from "./slice/who-we-are/news-slice";
import CatalogTabsReducer from "./slice/education/catalog-tabs-slice";
import CatalogPathReducer from "./slice/education/catalog-path-slice";
import TypeFiltersReducer from "./slice/events/type-filters-slice";
import EventsSearchReducer from "./slice/events/events-search-slice";
import taxonomyProductReducer from "./slice/taxonomy-product-slice";
import taxonomyEventReducer from "./slice/taxonomy-event-slice";
import taxonomyUserReducer from "./slice/taxonomy-user-slice";
import EventRightSideBooksReducer from "./slice/right-side/event-right-side-books-slice";
import EventRightSideLearningReducer from "./slice/right-side/event-right-side-learning-slice";
import EventRightSideNewsReducer from "./slice/right-side/event-right-side-news-slice";
import EventRightSideRelatedEventsReducer from "./slice/right-side/event-right-side-related-events-slice";
import ProductRightSideBookReducer from "./slice/right-side/product-right-side-books-slice";
import ProductRightSideLearningReducer from "./slice/right-side/product-right-side-learning-slice";
import ProductRightSideNewsReducer from "./slice/right-side/product-right-side-news-slice";
import ProductRightSideRelatedEventsReducer from "./slice/right-side/product-right-side-related-events-slice";

import BooksTabsReducer from "./slice/education/books-tabs-slice";
import BlackFridayReducer from "./slice/education/black-friday-slice";
import CurrentJobOpeningsReducer from "./slice/who-we-are/current-job-openings-slice";
import ArticlesFilteredsReducer from "./slice/news-articles/articles-filtered-slice";
import ArticlesFiltereds393Reducer from "./slice/news-articles/articles-filtered393-slice";
import ArticlesFiltereds397Reducer from "./slice/news-articles/articles-filtered397-slice";
import ArticlesFiltereds398Reducer from "./slice/news-articles/articles-filtered398-slice";
import ArticlesFiltereds399Reducer from "./slice/news-articles/articles-filtered399-slice";
import ArticlesFiltereds400Reducer from "./slice/news-articles/articles-filtered400-slice";
import ArticlesFiltereds404Reducer from "./slice/news-articles/articles-filtered404-slice";
import ArticlesPinnedReducer from "./slice/news-articles/articles-pinned-slice";
import FeatureFocusReducer from "./slice/news-articles/regulatory-focus/feature-focus-slice";
import ForAuthorsReducer from "./slice/news-articles/regulatory-focus/for-authors-slice";
import LatestFeaturesReducer from "./slice/news-articles/regulatory-focus/latest-features-slice";
import RFQuarterlyReducer from "./slice/news-articles/regulatory-focus/rf-quarterly-slice";
import {HeroJoinReducer, HeroJoin2Reducer} from "./slice/hero-join.slice";
import LandingBannersReducer from "./slice/landing-banners.slice";
import MyProgramsReducer from "./slice/events/event-myprograms.slice";
import VideoReducer from "./slice/events/event-video.slice";
import UserInfoReducer from "./slice/sso/userinfo.slice";
import AllBooksReducer from "./slice/education/all-books-slice";
import AllCoursesReducer from "./slice/education/all-courses-slice";
import AccordionReducer from "./slice/accordion-slice";
import AccordionScheduleReducer from "./slice/accordion-schedule-slice";
import AccordionProfileReducer from "./slice/accordion-profile-slice";
import SliderReducer from "./slice/slider-slice"; 
import AllBooksInfoReducer from "./slice/all-booksinfo-slice";
import BestSellersReducer from "./slice/bestsellers-slice";
import CelebrityAwardeesReducer from "./slice/celebrity-awardees-slice"
import PageMenuEurope2023BannerReducer from "./slice/convergence/PageMenuEurope2023Banner.slice"
import SliderPhiladelphiaReducer from "./slice/slider-philadelphia-slice"
import EventCategoryReducer from "./slice/events/event-category.slice";
import CalloutsReducer from "./slice/home/callouts.slice";
import UrlRedirectReducer from "./slice/redirect-urls/url-redirect.slice";
import AllRedirectUrls from "./slice/redirect-urls/all-redirect-urls.slice";
import CventMemberTypeConvertReducer from "./slice/cvent-member-type-convert.slice";
import ImpexiumUserInfoReducer from "./slice/impexium/impexium-user-info.slice";
import ImpexiumSubscriptionsReducer from "./slice/impexium/impexium-subscriptions.slice";
import ImpexiumSubscriptionsUpdateReducer from "./slice/impexium/impexium-subscriptions-update.slice";
import ConvergenceFooterReducer from "./slice/convergence-footer-slice";
import ArticleDetailsReducer from "./slice/article-details-slice";
import FAQsReducer from "./slice/faq.slice";
import ConvergenceAnonymousFormSubmitReducer from "./slice/convergence/convergence-anonymous-form-submit-slice"
import EuroAnonymousFormSubmitReducer from "./slice/convergence/euro-anonymous-form-submit-slice"
import ImpexiumUserCreateReducer from "./slice/impexium/impexium-user-create.slice"

// Convergence
import ConvergencePageMenuReducer from "./slice/convergence/convergence-pages-menu-slice";
import NewsArticlesRightSideRelatedEventsReducer from "./slice/right-side/news-articles-right-side-related-events-slice";
import NewsArticlesRightSideNewsReducer from "./slice/right-side/news-articles-right-side-news-slice";
import NewsArticlesRightSideLearningReducer from "./slice/right-side/news-articles-right-side-learning-slice";
import NewsArticlesRightSideBooksReducer from "./slice/right-side/news-articles-right-side-books-slice";
import NewsArticlesRightSideTaxonomyReducer from "./slice/right-side/new-articles-right-side-taxonomy-slice";
import SiteMapReducer from "./slice/sitemap-slice";

import MyContent from "./slice/my-content.slice";

const reducers = {
  login: authenticationReducer.login,
  logout: authenticationReducer.logout,
  ssoLogin: authenticationReducer.ssoLogin,
  ssoLogout: authenticationReducer.ssoLogout,
  loggedIn: authenticationReducer.isLoggedIn,
  userInfo: UserInfoReducer,
  primaryMenu: primaryMenuReducer,
  convergenceCarouselSlides: convergenceCarouselSlidesSliceReducer,
  taxonomyList: taxonomyReducer,
  latestArticles: latestArticlesReducer,
  latestBooks: latestBooksReducer,
  latestCourses: latestCoursesReducer,
  latestEvents: latestEventsReducer,
  rapsHighlights: rapsHighlightsReducer,
  eventCalendar: eventCalendarReducer.eventCalendar,
  eventCalendarByMonth: eventCalendarReducer.eventCalendarByMonth,
  convergenceCarousel: convergenceCarouselReducer,
  notification: notificationReducer,
  masterPageConfiguration: masterPageConfigurationReducer,
  topArticlesForHomePage: topArticlesForHomePageReducer,
  homeRegulatoryExchange: homeRegulatoryExchangeReducer,
  latestDiscussion: latestDiscussionReducer,
  socialMediaContent: socialMediaContentReducer,
  footerMenu: footerMenuReducer,
  regulatoryExchange: regulatoryExchangeReducer,
  cookieLaw: cookieLawReducer,
  rapsEurope: pageMenuReducer,
  PageMenuEurope2023Banner: PageMenuEurope2023BannerReducer,
  regulatoryFocus: RegulatoryFocusReducer,
  boardOfDirectors: boardOfDirectorsReducer,
  editorialAdvisoryCommittee: editorialAdvisoryCommitteeReducer,
  searchRacExam: searchRacExamReducer,
  breadcrumb: BreadcrumbReducer,
  searchFilters: searcFiltersReducer.sector,
  searchFiltersRegion: searcFiltersReducer.region,
  searchFiltersProfInt: searcFiltersReducer.profInt,
  searchSortBy: searchSortByReducer,
  searchPageTabs: searchPageTabsReducer,
  search: searchReducer,
  preferences: preferencesReducer,
  eventDetails: eventDetailsReducer,
  localNetwork: localNetworkReducer,
  ProductDetails: ProductDetailsReducer,
  newsArticlesSearch: newsArticlesSearcReducer,
  newsArticlesSearchCategory: newsArticlesSearchCategoryReducer,
  newsArticlesSearchAuthor: newsArticlesSearchAuthorReducer,
  racMenu: racMenuReducer,
  bookSale: BookSaleReducer,
  EducationPages: EducationPagesReducer,
  WhoToArePressReleases: WhoToArePressReleasesReducer,
  WhoToAreNews: WhoToAreNewsReducer,
  CatalogTabs: CatalogTabsReducer,
  CatalogPath: CatalogPathReducer,
  TypeFilters: TypeFiltersReducer,
  EventsSearch: EventsSearchReducer,
  taxonomyProduct: taxonomyProductReducer,
  taxonomyEvent: taxonomyEventReducer,
  taxonomyUser: taxonomyUserReducer,
  EventRightSideBooks: EventRightSideBooksReducer,
  EventRightSideLearning: EventRightSideLearningReducer,
  EventRightSideNews: EventRightSideNewsReducer,
  EventRightSideRelatedEvents: EventRightSideRelatedEventsReducer,
  ProductRightSideBook: ProductRightSideBookReducer,
  ProductRightSideLearning: ProductRightSideLearningReducer,
  ProductRightSideNews: ProductRightSideNewsReducer,
  ProductRightSideRelatedEvents: ProductRightSideRelatedEventsReducer,
  BooksTabs: BooksTabsReducer,
  BlackFriday: BlackFridayReducer,
  CurrentJobOpenings: CurrentJobOpeningsReducer,
  ArticlesFiltereds: ArticlesFilteredsReducer,
  ArticlesFiltereds393: ArticlesFiltereds393Reducer,
  ArticlesFiltereds397: ArticlesFiltereds397Reducer,
  ArticlesFiltereds398: ArticlesFiltereds398Reducer,
  ArticlesFiltereds399: ArticlesFiltereds399Reducer,
  ArticlesFiltereds400: ArticlesFiltereds400Reducer,
  ArticlesFiltereds404: ArticlesFiltereds404Reducer,
  ArticlesPinned: ArticlesPinnedReducer,
  FeatureFocus: FeatureFocusReducer,
  ForAuthors: ForAuthorsReducer,
  LatestFeatures: LatestFeaturesReducer,
  RFQuarterly: RFQuarterlyReducer,
  landingBanner: LandingBannersReducer,
  myPrograms: MyProgramsReducer,
  video: VideoReducer,
  heroJoin: HeroJoinReducer,
  AllBooks: AllBooksReducer,
  AllCourses: AllCoursesReducer,
  Accordion: AccordionReducer,
  AccordionSchedule: AccordionScheduleReducer,
  AccordionProfile: AccordionProfileReducer,
  BestSellers: BestSellersReducer,
  AllBooksInfo: AllBooksInfoReducer,
  eventCategory: EventCategoryReducer,
  callouts: CalloutsReducer,
  urlRedirect: UrlRedirectReducer,
  allRedirectUrls: AllRedirectUrls,
  cventMemberTypeConvert: CventMemberTypeConvertReducer,
  impexiumUserInfo: ImpexiumUserInfoReducer,
  impexiumSubscriptions: ImpexiumSubscriptionsReducer,
  impexiumSubscriptionsUpdate: ImpexiumSubscriptionsUpdateReducer,
  faqs: FAQsReducer,
  impexiumUserCreate: ImpexiumUserCreateReducer,
  myContentNews: MyContent.news,
  myContentEvents: MyContent.events,
  myContentBooks: MyContent.books,
  myContentLearning: MyContent.learning,
  myContentTaxonomies: MyContent.taxonomies,
  // Convergence
  ConvergencePageMenu: ConvergencePageMenuReducer,
  heroJoin2: HeroJoin2Reducer,
  Slider: SliderReducer,
  SliderPhiladelphia: SliderPhiladelphiaReducer,
  CelebrityAwardees: CelebrityAwardeesReducer,
  ArticleDetails: ArticleDetailsReducer,
  ConvergenceFooter: ConvergenceFooterReducer,
  ConvergenceAnonymousFormSubmit: ConvergenceAnonymousFormSubmitReducer,
  EurogenceAnonymousFormSubmit: EuroAnonymousFormSubmitReducer,
  SiteMap: SiteMapReducer,
  NewsArticlesRightSideRelatedEvents: NewsArticlesRightSideRelatedEventsReducer,
  NewsArticlesRightSideNews: NewsArticlesRightSideNewsReducer,
  NewsArticlesRightSideLearning: NewsArticlesRightSideLearningReducer,
  NewsArticlesRightSideBooks: NewsArticlesRightSideBooksReducer,
  NewsArticlesRightSideTaxonomy: NewsArticlesRightSideTaxonomyReducer,
};

export const Store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;

// eslint-disable-next-line
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
