import React, { useEffect }  from 'react'
import HeaderNav from './header-nav';
// import { Link } from "react-router-dom";
import LoginComponent from './login-component';
import MainSearch from './main-search';
import MegaMenu from './mega-menu';
import { CtxProvider } from './menu-context';
import { retrievePrimaryMenu } from "../../redux/slice/primary-menu-slice";
import { RootState, useAppDispatch } from "../../redux/store";
import { useSelector } from 'react-redux';


const HeaderBar:React.FC = () =>  {
    const {data} = useSelector((state: RootState) => state.primaryMenu);

    const dispatch = useAppDispatch()

    useEffect(() => {
       dispatch(retrievePrimaryMenu())
    }, [])
    
    return (
        <CtxProvider>
          <header id="site-header" className="site-header site-header-paddingCustom" role="banner">
            <MainSearch />
            <LoginComponent />
            <HeaderNav menu={data} />
            
            <MegaMenu menu={data} />
          </header>
    
        </CtxProvider>
    );
}

export default HeaderBar;
