import { useEffect, useMemo } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/images/RAPS-Logo.png";
import { useDynamicScripts } from "../../hooks/useDynamicScript";
import { retrieveConvergencePageMenu } from "../../redux/slice/convergence/convergence-pages-menu-slice";
import { retrieveMasterPageConfiguration } from "../../redux/slice/master-page-configuration-slice";
import { retrieveUserInfo } from "../../redux/slice/sso/userinfo.slice";
import { RootState, useAppDispatch } from "../../redux/store";
import useAuth from "../../hooks/useAuth";
import LoadAds from "../../components/advertisement/load-convergence-ads";

const ConvergenceHeader = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const path = "%2FConvergence-2024%2FSite-Nav";
  const { masterPageConfigurationData } = useSelector((state: RootState) => state.masterPageConfiguration);
  const { ConvergencePagesMenuData } = useSelector((state: RootState) => state.ConvergencePageMenu);
  const { checkLogin } = useSelector((state: RootState) => state.loggedIn);
  const userInfo = useSelector((state: RootState) => state.userInfo);
  const userInfoData = userInfo.data;
  const cachedGoogleAdsLoader = useMemo(() => <LoadAds />, []);

  useEffect(() => {
    dispatch(retrieveConvergencePageMenu(path));
    dispatch(retrieveMasterPageConfiguration());
  }, [dispatch]);

  useEffect(() => {
    if (!userInfo.loading && auth.getUser()?.accessToken && userInfo.data?.result === undefined) {
      dispatch(retrieveUserInfo());
    }
  }, [dispatch]);

  useDynamicScripts({ htmlScriptString: masterPageConfigurationData[0]?.masterPageData[0]?.globalScripts || "", divId: "globalScript" });

  return (
    <>
      {cachedGoogleAdsLoader}
      <header>
        <Navbar bg="dark" className="bg-primary sticky-top navbar-dark" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {ConvergencePagesMenuData[0]?.navBarItemsList?.map((item, index) => {
                return item.childNavigation?.length === 0 ? (
                  <Nav.Link href={item.url} key={index} target={item.isTargetBlank ? "_blank" : ""} dangerouslySetInnerHTML={{ __html: item.text }}></Nav.Link>
                ) : (
                  <NavDropdown id={"nav-dropdown-dark-example-" + index} title={item.text} menuVariant="dark" key={index} renderMenuOnMount={true}>
                    {item.childNavigation?.map((itm, indx) => {
                      return <NavDropdown.Item href={itm.url} key={indx} target={itm.isTargetBlank ? "_blank" : ""} dangerouslySetInnerHTML={{ __html: itm.text }}></NavDropdown.Item>;
                    })}
                  </NavDropdown>
                );
              })}
            </Nav>
          </Navbar.Collapse>
          <div className="form-inline">
            {checkLogin.isLoggedIn && userInfoData.result && <span className="navbar-brand greeting">Hi, {userInfoData.result?.firstName}</span>}
            <Link className="navbar-brand" style={{ marginRight: 0 }} to="/">
              <img alt="RAPS.org" src={logo} />
            </Link>
          </div>
        </Navbar>
      </header>
    </>
  );
};

export default ConvergenceHeader;