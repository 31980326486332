import { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MicrositeRoutes from "../routes/rpf-routes";
import LoadingComp from "../components/re-usable/loading/loading";
import Header from "../components/header/rpf-header";
import ConferenceLayoutStyleComponent from "./components/eucrm-layout-style.component";

const Layout = () => {
  return (
    <>
      <ConferenceLayoutStyleComponent />
      <div>
        <Header />
        <Suspense fallback={<LoadingComp />}>
          <Routes>
            {MicrositeRoutes.map((route, index) => {
              return route.component ? <Route key={index} path={route.path} element={route.component} /> : null;
            })}
          </Routes>
        </Suspense>
      </div >
    </>
  );
};

export default Layout;